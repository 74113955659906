img.img-fluid{
  max-width: 100%;
}

.opacity-0 {
 opacity: 0;
}

.text-decoration-none{
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.block {
  display: block;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
