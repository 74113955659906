$header-height: 850;

header {
  height: calc(100vh - #{inRem(11 * 16)});
  //height: auto;

  @include  problemQuery(inHeight) {
    height: 100vh;
  }

  img.sizer {
    display: none;
  }

  @include medium(down) {
    height: auto;

    img.sizer {
      display: block;
      margin-bottom: -8px;
    }
  }

  @include small(down) {
    img.sizer {
      margin-bottom: -4px;
    }
  }

  @include xsmall(down) {
    img.sizer {
      margin-bottom: -2px;
    }
  }


  &.visible {
    background: url("../img/header.jpg") no-repeat center top;
    background-size: cover;
    background-attachment: fixed;

    @include medium(down) {
      background-size: contain;
    }
  }

  &.subpage {
    height: inRem(60);
    &.visible {
      background: none;
    }
  }
}
