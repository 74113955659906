section.single-promo-article {
  min-height: calc(70vh - #{inRem(60)});
  padding-top: inRem(16);
  background: url('../img/promo_bg_small.svg') 64vw -180px no-repeat;
  background-size: 400px;
  display: flex;
  flex-direction: column;

  .section-title {
    img {
      margin-right: -151px;

      @include xsmall(down) {
        margin-right: -100px;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    article {
      height: 100%;
      padding-top: inRem(64);
      padding-bottom: inRem(64);

      &.has-image {

        .content {
          display: flex;

          @include small(down) {
            flex-direction: column-reverse;
          }

          .promo-image {
            width: 30%;
            margin-right: inRem(32);

            @include small(down) {
              width: 100%;
              margin-right: 0;
            }

            img {

            }
          }

          .text {
            width: calc(70% - #{inRem(32)});
          }

          @include small(down) {
            .text {
              margin-bottom: inRem(16);
              width: 100%;
            }
          }

        }
      }

      h2 {
        margin-bottom: inRem(16);
        line-height: inRem(22);
        //letter-spacing: inRem(1);
        color: $pink;
        @include fontBold(inRem(20));
      }

      .content {
        text-align: justify;
      }
    }
  }
}
