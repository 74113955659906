footer {
  background: url("../img/logo_brown.svg") left inRem(150) no-repeat;
  background-size: inRem(600);
  background-color: $brown-light;

  &.error-page {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .container {
    padding-top: inRem(32);
    padding-bottom: inRem(32);

    .wrapper-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: inRem(2);
      width: 100%;

      @include medium(down) {
        grid-template-columns: repeat(1, 1fr);
      }

      .col {
        color: $white;

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include medium(down) {
          align-items: center;
          margin-bottom: inRem(32);

          .inner {
            width: 60%;
          }
        }

        @include small(down) {
          .inner {
            width: 100%;
          }
        }


        &:after {
          content: "";
          display: block;
          position: absolute;
          right: inRem(2, minus);
          top: 0;
          height: 100%;
          width: inRem(2);
          background-color: rgba($white, 0.8);
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        @include medium(down) {
          &:after {
            display: none;
          }
        }

        a {
          color: $white;
          text-decoration: none;
        }

        .col-head {
          h3 {
            @include fontBold(inRem(18));
            text-transform: uppercase;
            margin-bottom: inRem(24);
          }
        }

        .col-content {
          a {
            @include fontLight(inRem(16));
          }

          .c-row {
            display: flex;
            flex-direction: column;
            @include fontLight(inRem(16));

            &.mb {
              margin-bottom: inRem(24);
            }

            span {
              @include fontLight(inRem(16));
              margin-bottom: inRem(6);
            }

            a {
              margin-bottom: inRem(8);
            }

            .hours {
              display: flex;
              flex-direction: column;
              .h-row {
                display: flex;
                margin-bottom: inRem(6);
                .day {

                }

                .hour {
                  margin-left: inRem(24);
                }
              }
            }

            .cc-row {
              display: flex;
              flex-direction: column;
              margin-bottom: inRem(16);

              .label {
                margin-bottom: inRem(6);
              }
            }
          }
        }
      }
    }
  }
}
