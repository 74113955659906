section.page404 {
  .container {
    min-height: inRem(260);
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      @include fontBold(inRem(32));
      color: $brown;
    }
  }
}
