section.contact {
  min-height: calc(100vh - #{inRem(60)});
  display: flex;
  flex-direction: column;

  .top-wrapper {
    padding-top: inRem(16);
    height: inRem(176);
    background: url('../img/pink_logo_bg_less_alpha.svg') 100% 100% no-repeat;
    background-size: 47vw;

    .section-title {
      img {
        margin-right: -180px;

        @include xsmall(down) {
          margin-right: -100px;
        }
      }
    }
  }

  &.subpage {
    .top-wrapper {
      background: none;
    }
  }

  .content-wrapper {
    display: flex;
    background: url('../img/pink_logo_bg_alpha.svg') repeat;
    background-size: inRem(100);
    height: auto;

    @include medium(down) {
      flex-direction: column;
      height: auto;
      align-items: center;
    }

    .side-left {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: calc(100vw * 0.55);

      @include medium(down) {
        width: $content-width;
      }

      @include xlarge(open) {
        width: calc(100vw - (#{inRem($content-width-lg)} * 0.55));
      }

      @include xxxlarge {
        width: calc(100vw - (#{inRem($content-width-hq)} * 0.55));
      }

      .image {
        width: 100%;

        img {
          height: inRem(340);
          width: 100%;
          margin-bottom: inRem(3, minus);

          @include medium(down) {
            height: auto;
          }
        }
      }

      .map-view {
        width: calc(100% - 10vw);
        height: 100%;

        @include medium(down) {
          width: 100%;
        }

        @include xlarge(open) {
          width: calc(100% - ((100vw - #{inRem($content-width-lg)}) / 2));
        }

        @include xxxlarge {
          width: calc(100% - ((100vw - #{inRem($content-width-hq)}) / 2));
        }

        .map-title {
          display: flex;
          align-items: center;
          background-color: $brown-light;
          min-height: inRem(90);
          padding: 0 inRem(24) 0 inRem(24);
          h2 {
            color: $white;
            @include fontLight(inRem(32));
          }
        }

        .map-iframe {
          height: calc(100% - #{inRem(90)});

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .side-right {
      width: calc(100vw * 0.45);

      @include medium(down) {
        width: $content-width;
      }

      @include xlarge(open) {
        width: calc(100vw - (#{inRem($content-width-lg)} * 0.45));
      }

      @include xxxlarge {
        width: calc(100vw - (#{inRem($content-width-hq)} * 0.45));
      }

      .data {
        width: calc(100% - 10vw);

        @include medium(down) {
          width: 100%;
        }

        @include xlarge(open) {
          width: calc(100% - ((100vw - #{inRem($content-width-lg)}) / 2));
        }

        @include xxxlarge {
          width: calc(100% - ((100vw - #{inRem($content-width-hq)}) / 2));
        }

        .data-head {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: $brown-light;
          padding: inRem(32) inRem(32);
          border-radius: 0 inRem(80) 0 0;

          @include medium(down) {
            border-radius: 0;
          }

          .wrapper-row {
            width: 100%;
          }

          .title-wrapper {
            display: flex;
            align-items: center;
            color: $white;

            .logo {
              width: inRem(90);
            }

            .firm-name {
              padding-left: inRem(16);
              h2 {
                @include fontNillandBlack(inRem(32));
                letter-spacing: inRem(1);
                text-transform: lowercase;
              }
            }
          }

          .address-wrapper {
            padding-left: inRem(106);
            color: $white;
            margin-bottom: inRem(24);

            @include xsmall(down) {
              padding-left: 0;
              margin-top: inRem(12);
            }

            .p-row {
              @include fontLight(inRem(20));
              text-transform: uppercase;
              line-height: inRem(24);
            }
          }

          .social-wrapper {
            display: flex;
            padding-left: inRem(106);

            @include xsmall(down) {
              padding-left: 0;
            }

            a {
              margin-right: inRem(16);
              img {
                width: inRem(32);
              }
            }
          }
        }
        .data-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: $brown;
          padding: inRem(32);

          a {
            color: $brown;
            text-decoration: none;
          }

          .all-wrapper {
            width: 100%;

            @include medium(down) {
              width: 60%;
            }

            @include small(down) {
              width: 100%;
            }

            .mn-row {
              width: 100%;
              margin-bottom: inRem(32);

              .title-row {
                display: flex;
                margin-bottom: inRem(6);

                @include xxsmall {
                  flex-direction: column;
                }

                &.m-top {
                  margin-bottom: inRem(16);
                }

                .label {
                  width: 40%;
                  @include fontBold(inRem(16));
                  text-transform: uppercase;

                  @include xxsmall {
                    width: 100%;
                    margin-bottom: inRem(8);
                  }
                }

                .value {
                  width: 60%;
                  @include fontBold(inRem(16));

                  @include xxsmall {
                    width: 100%;
                  }
                }
              }

              .data-row {
                margin-left: 40%;
                line-height: inRem(24);

                @include xxsmall {
                  margin-left: 0;
                }
              }

              .content {
              }

              .phone {
              }

              .email {
              }

              .web {
              }
            }
          }
        }
      }
    }
  }
}
