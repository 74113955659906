$white: #FFFFFF;
$black: #000000;
$gray-font: #646464;
$pink: #d60058;
$pink-light: #fab5d2;
$pink-super-light: #f8e6ea;
$blue: #1d9adc;
$brown: #8a6727;
$brown-light: #b5955a;
$brown-super-light: #e6cda4;
