.section-title {
  width: 40%;
  height: inRem(120);
  border-radius: 0 inRem(160) inRem(160) 0;
  display: flex;
  justify-content: flex-end;

  @include xsmall(down) {
    width: 50%;
  }

  &.pink-sc {
    background-color: $pink;
  }

  &.blue-sc {
    background-color: $blue;
  }

  &.brown-sc {
    background-color: $brown-light;
  }

  img {
    height: inRem(150);
    margin-top: inRem(10);

    @include xsmall(down) {
      margin-top: inRem(40);
      height: inRem(100);
    }
  }
}
