$image-plan-bg-height: 202;
section.plan {
  position: relative;
  min-height: calc(100vh - #{inRem(60)});
  padding-top: inRem(16);

  @include medium(down) {
    min-height: auto;
  }

  &.visible {
    &::before {
      position: absolute;
      content: '';
      z-index: -3;
      left: 0;
      top: 0;
      width: 100%;
      height: inRem($image-plan-bg-height);
      background:  url("../img/plan_bg.jpg") center top no-repeat;
      background-size: cover;
      background-attachment: fixed;
    }

    span.blue-bg {
      position: absolute;
      content: '';
      z-index: -2;
      left: 0;
      top: inRem($image-plan-bg-height);
      width: 100%;
      height: calc(100% - #{inRem($image-plan-bg-height)});
      background: $blue;
    }

    span.logo-bg {
      position: absolute;
      content: '';
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url("../img/pink_logo_bg_alpha.svg") 100% -60% no-repeat;
      background-size: 50vw;
    }
  }

  .section-title {
    img {
      margin-right: -260px;

      @include xsmall(down) {
        margin-right: -100px;
      }
    }
  }

  .content {
    //background-color: $blue;
    margin-top: inRem(64);
    min-height: calc(100vh - 370px);
    color: $white;

    .container {
      min-height: calc(100vh - 370px);
      display: flex;
      flex-direction: column;

      .plan-buttons{
        display: flex;
        justify-content: flex-end;

        .plan-buttons-wrapper {
          display: flex;
          justify-content: center;
          width: 70%;
          margin-top: inRem(16, minus);

          @include medium(down) {
            width: 100%;
          }

          button.plan-btn {
            display: flex;
            align-items: center;
            background-color: $white;
            border-radius: inRem(20);
            padding: inRem(6) inRem(12);
            @include fontBold();
            color: $brown-light;
            border: 1px solid $brown-light;

            &:first-child{
              margin-right: inRem(12);
            }

            span {
              display: flex;
              width: inRem(20);
              height: inRem(20);
              align-items: center;
              justify-content: center;
              background-color: $brown-light;
              color: $white;
              border-radius: inRem(50);
              margin-left: inRem(12);
            }

            &.active {
              background-color: $brown-light;
              color: $white;

              span {
                background-color: $white;
                color: $brown-light;
              }
            }
          }
        }
      }

      .plan-details{
        display: flex;
        margin-top: inRem(24);

        @include medium(down) {
          flex-direction: column-reverse;
        }

        .shop-lists {
          display: flex;
          justify-content: center;
          width: 30%;

          @include medium(down) {
            width: 100%;
          }

          ul.shop-list {
            display: none;
            //min-height: 58vh;

            &.visible {
              display: block;

              @include medium(down) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-top: inRem(24);
                margin-bottom: inRem(32);
              }

              @include xsmall(down) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                margin-top: inRem(24);
                margin-bottom: inRem(32);
              }
            }

            li {
              @include fontRegular(inRem(14));
              line-height: inRem(16);
            }
          }
        }

        .floor-map {
          width: 70%;
          display: flex;
          justify-content: center;

          @include medium(down) {
            width: 100%;
          }

          img{
            display: none;
            max-width: 100%;

            &.visible {
              display: block;
              height: 60vh;
            }

            @include small(down) {
              &.visible {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
