section.promotions {
  min-height: calc(100vh - #{inRem(60)});
  padding-top: inRem(16);
  background: url("../img/promo_bg_small.svg") -20vw 50vh no-repeat, url("../img/promo_bg_small.svg") 66vw -180px no-repeat;
  background-size: 800px, 450px;

  &.no-promo {
    min-height: auto;
  }

  .section-title {
    img {
      margin-right: -151px;

      @include xsmall(down) {
        margin-right: -100px;
      }
    }
  }

  .promo-articles {
    padding: inRem(64) 0 inRem(32) 0;
    .arts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: inRem(96);

      @include medium(down) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: inRem(96);
      }

      @include xsmall(down) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: inRem(0);
      }

      .art-col {
        @include xsmall(down) {
          margin-bottom: inRem(48);
        }

        .intro-image {
          margin-bottom: inRem(16);

          @include xsmall(down) {
            img {
              width: 100%;
            }
          }
        }

        h3 {
          margin-bottom: inRem(16);
          line-height: inRem(22);
          //letter-spacing: inRem(1);
          a {
            color: $pink;
            @include fontBold(inRem(20));
            //@include fontNillandBlack(inRem(20));
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .intro {
          text-align: justify;
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin-top: inRem(32);

      ul.pagination-ul {
        display: flex;
        align-items: center;
        justify-content: center;

        @include xsmall(down) {
          flex-wrap: wrap;
        }

        li {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: $pink;
            padding: inRem(8) inRem(12);
            @include fontRegular(inRem(18));
            border-radius: inRem(80);

            &:hover {
              text-decoration: none;
            }
          }

          &.only-btn {
            a {

            }
          }

          &.active {
            a {
              background-color: $pink;
              color: $white;
            }
          }

          &.prev-btn {

          }

          &.first-btn{

          }

          &.next-btn {

          }

          &.last-btn {

          }
        }
      }
    }
  }
}
