@font-face {
  font-family: "Nilland-Black";
  src: url('webfonts/Nilland-Black-900.eot');
  src: url('webfonts/Nilland-Black-900.eot') format('embedded-opentype'),
  url('webfonts/Nilland-Black-900.woff2') format('woff2'),
  url('webfonts/Nilland-Black-900.woff') format('woff'),
  url('webfonts/Nilland-Black-900.ttf') format('truetype'),
  url("webfonts/Nilland-Black-900.svg#filename") format("svg");
}

@mixin fontNillandBlack($size: 1rem) {
  font-family: 'Nilland-Black';
  font-size: $size;
  font-weight: 900;
}

@mixin fontExtraLight($size: 1rem, $style: normal) {
  font-family: 'Roboto', sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 100;
}

@mixin fontLight($size: 1rem, $style: normal) {
  font-family: 'Roboto', sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 300;
}

@mixin fontRegular($size: 1rem, $style: normal) {
  font-family: 'Roboto', sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 400;
}

@mixin fontSemiBold($size: 1rem, $style: normal) {
  font-family: 'Roboto', sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 500;
}

@mixin fontBold($size: 1rem, $style: normal) {
  font-family: 'Roboto', sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 600;
}
