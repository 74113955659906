.container {
  margin: auto;
  width: $content-width-mobile;

  @include xlarge(open) {
    width: inRem($content-width-lg);
  }

  @include xxxlarge {
    width: inRem($content-width-hq);
  }
}

